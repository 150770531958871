.form-work-request-update-shipping-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 1rem;
}

.sample-safety-warning-external {
    display: flex;
    gap: 10px;
}