.new-work-request-redesign {
    .summary-title {
        font-family: "EMprint";
        font-weight: 600;
        font-size: 20px;
        color: #545459;
        margin-bottom: 35px;
        text-transform: none;
        text-align: left;
        padding-left: 20px;
    }

    .summary-button {
        font-family: "EMprint";
        font-weight: 600;
        font-size: 14px;
        align-self: flex-start;
        text-transform: none;
        padding-left: 20px;
        color: #666666;
        margin-bottom: 25px;
    }

    .active {
        color: #1460A6;
    }

    .container-buttons {
        bottom: 0;
        position: absolute; 
        margin-bottom: 2rem;
        width: 100%;
        padding: 0px 15px;

        .action-button {
            text-transform: none;
            margin-top: 20px;
            width: 100%
        }
    }

    .work-request-shipping-information {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .header-button {
            display: flex;
            justify-content: flex-end;
            .button {
                margin-top: 1.1rem;
                width: 12rem;
                margin-left: 15px;
            }
        }

        .first-grid {
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 2fr 1fr 1fr 2fr;
            gap: 1rem;
            height: 60
        }
        .second-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: 1rem;
            height: 60
        }

        .shipping-label {
            font-family: EMprint;
            font-weight: 600;
            font-size: 16px;
            color: #545459;
            text-transform: none;
            text-align: left;
            padding-top: 20px;
        }
    }

}