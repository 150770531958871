.dialog-parent-tests {
  .header {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    .header-box {
      display: flex;
      gap: 8px;
      p {
        font-size: 1rem;
        height: 100;
      }
    }
  }
}