/* 
  search: {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },\*/

.searchIcon {
  height: 24px;
  /* position: relative; */
  padding-right: 10px;
  padding-top: 8px;
}
.midasHeader {
  top: 0px;
  left: 0px;
  height: 58px;
  z-index: 1300 !important;
  background: transparent linear-gradient(93deg, #3a397b 0%, #0c69b0 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.midasHeaderACC {
  top: 0px;
  left: 0px;
  height: 58px;
  z-index: 1300 !important;
  background: transparent linear-gradient(93deg, #3a397b 0%, #b00c0c 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.midasHeaderDEV {
  top: 0px;
  left: 0px;
  height: 58px;
  z-index: 1300 !important;
  background: transparent linear-gradient(93deg, #3a397b 0%, #c9f102 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.midasLogo {
  top: -4px;
  left: -15px;
  position: relative;
}

.headerFont {
  font: normal normal 600 15px/19px EMprint; /*font weight, font size/line spacing */
  color: #ffffffcc;
}

.searchInput {
  color: rgb(255 255 255);
  font: normal normal 400 1px EMprint;
  width: 100%;
}

.search {
  min-width: 435px;
  width: 40%;
  margin-top: -5px;
}

.MuiInputBase-input {
  padding-left: 10px !important;
}

.MuiAutocomplete-option {
  font-family: EMPrint;
}
