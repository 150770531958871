.container-stlc {
  min-height: calc(100vh - 100px);
  padding: 30px;

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    .header-box {
      display: flex;
      align-items: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    margin-top: 20px;

    .tradename {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      gap: 5px;
    }

    .link-tables {
      border-left: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      width: 20px;
      height: 85px;
      position: fixed;
      margin-left: -20px;
      margin-top: -15px;
    }

    .link-with-itens {
      margin-top: -20px;
      height: 90px;
    }

    .lot {
      display: flex;
      flex-direction: column;
      margin-left: 60px;
      gap: 5px;
    }

  }


}

.stlc-search-box {
  display: flex;
  align-items: center;
}