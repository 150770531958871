.pageheader {
  font: normal normal 600 30px/26px EMprint;
}
.dividerbar {
  margin-left: -40px !important;
  margin-top: 15px !important;
  margin-bottom: 25px !important;
  width: calc(100% + 80px);
}

.homebanner {
  background-image: url("../../assets/homepagebackground.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerBabyG {
  background-image: url("../../assets/MIDAS3BabyGbanner.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerDecember {
  background-image: url("../../assets/MIDAS3-December1.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerChristmas {
  background-image: url("../../assets/MIDAS3-Christmas1.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerEarlySpring {
  background-image: url("../../assets/MIDAS3-EarlySpring1.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerHalloween {
  background-image: url("../../assets/MIDAS3-Halloween.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerNewYearsDay {
  background-image: url("../../assets/MIDAS3-NewYearsDay.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerPiDay {
  background-image: url("../../assets/MIDAS3-PiDay.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerStPatricks {
  background-image: url("../../assets/MIDAS3-StPatricksDay.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerWinter1 {
  background-image: url("../../assets/MIDAS3-Winter1.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerHTP {
  background-image: url("../../assets/HTPMIDAS.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerThanksgiving {
  background-image: url("../../assets/MIDAS3-Thanksgiving.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerNovember {
  background-image: url("../../assets/MIDAS3-November1.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-right: -0.5vw;
  margin-left: -40px;
}
.homebannerPunchCard {
    background-image: url("../../assets/IBMPunchCard.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    margin-right: -0.5vw;
    margin-left: -40px;
}
.bannerlist {
  position: relative;
  /* bottom: 0px; */
  left: 25px;
  min-width: 220px;
  padding-bottom: 15px;
}
.bannerheader {
  margin-top: 10px;
  margin-bottom: 5px;
  font: normal normal 600 17px/28px EMprint;
  margin-left: 16px;
}

.MuiListItemIcon-root {
  min-width: 35px !important;
}
.MuiListItem-dense {
  padding-top: 1px !important;
  padding-bottom: 2px !important;
}
.listcontents {
  text-decoration: underline;
  font: normal normal normal 12px/15px EMprint !important;
  color: #2175b6;
}
.MuiTypography-root {
  font-family: EMPrint !important;
}

.homepagehyperlinks {
  color: #3190c9 !important;
}

.MuiTableCell-root {
  font-family: EMPrint !important;
}

.homeTableHeader {
  vertical-align: middle;
  padding-bottom: 10px;
  padding-left: 15px;
}

.homeTables {
  padding-top: 30px;
  margin-left: -34px !important;
}
.small-header {
  vertical-align: middle;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-top: 15px;
}
