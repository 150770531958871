.container-management-stlc {
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .buttons {
      display: flex;
      gap: 10px;
    }
  }

  .content {
    margin-left: 10px;
    margin-top: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .column {
      display: flex;
      flex-direction: column;
    }

    .label {
      font-weight: 400;
    }
    .lot-name {
      font-size: 19px;
    }
  }
}

