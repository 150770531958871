#shipping-form-print {
   visibility: hidden;
   position: absolute;
   left: 0;
   top: 0;
}

@media print {
   body * {
      visibility: hidden;
   }
   #shipping-form-print,
   #shipping-form-print * {
      visibility: visible;
   }
   #shipping-form-print {
      position: absolute;
      left: 0;
      top: 0;
   }
}

.container {
   display: flex;
   width: 100%;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;

   .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;

      .label {
         font-size: 18px;
      }
   }

   .line-1 {
      display: flex;
      flex-direction: row;
      gap: 40px;
      width: 100%;

      .table-address {
         width: 100%;

         .tr-address {
            width: 100%;
            display: flex;

            .item {
               display: flex;
               justify-content: space-between;
               width: 80%;
            }

            .th-address {
               width: 100%;
               border: 1px solid black;
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               justify-content: space-between;
               min-height: 35px;
               padding: 2px;

               .item-title {
                  font-size: 10;
               }

               .item-description {
                  font-size: 10;
               }
            }

            .no-border-left {
               border-left: unset;
            }

            .no-border-right {
               border-right: unset;
            }
         }
      }

      .description-address {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         margin-top: 20px;
      }

      .details {
         width: 50%;
         display: flex;
         flex-direction: column;
         gap: 10px;
      }
   }

   .line-2 {
      padding-top: 10px;
      width: 100%;

      .table-items {
         width: 100%;

         .tr-message {
            .th-message {
               width: 100%;
               border: 1px solid black
            }
         }

         .tr-items {
            width: 100%;
            display: flex;

            .th-items {
               border: 1px solid black;
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               height: 35;
               padding: 2px;

               .item-title {
                  font-size: 10;
               }

               .item {
                  font-size: 10;
               }

               .item-description {
                  font-size: 10;
               }
            }

            .column-item-1 {
               width: 70px;
            }

            .column-item-2 {
               width: 70px;
            }

            .column-item-3 {
               width: 100%;
            }

            .column-item-4 {
               width: 70px;
            }

            .column-item-5 {
               width: 120px;
            }

            .column-item-6 {
               width: 70px;
            }

            .no-border-left {
               border-left: unset;
            }

            .no-border-right {
               border-right: unset;
            }
         }
      }
   }

   .line-3 {
      .reason-line {
         display: flex;
         gap: 10px;
         width: 100%;

         .group-reason-checkbox {
            display: flex;
            gap: 5px;
            align-items: center;
         }

         .first-column-reason {
            width: 210px;
         }

         .second-column-reason {
            width: 400px;
         }

         .third-column-reason {
            width: 400px;
         }
      }
   }

   .line-4 {
      .tsca-line {
         display: flex;
         gap: 5px;
         width: 100%;
         align-items: center;
      }
   }

   .input-container {
      display: flex;
      flex-direction: column;
      width: 280px;
      align-items: center;
   }

   .input {
      display: inline-block;
      border: unset;
      border-bottom: 1px solid black;
      width: 100%;
      margin-left: 10px;

      .helper-text {
         position: absolute;
         bottom: -20px;
         left: 20px;
      }
   }

   .rectangle {
      width: 15px;
      min-width: 15px;
      height: 20px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .group-container-checkbox {
      display: grid;
      grid-template-columns: 1fr 2fr;

      .container-checkbox {
         display: flex;
         gap: 5px;
         align-items: center;
         width: 200px;
      }
   }
}

span {
   font: normal normal 500 12px EMprint;
}

label {
   font: normal normal 500 12px EMprint;
}