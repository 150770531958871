.table_main {
    margin-top: 2rem;
    border-collapse: collapse;
    width:130rem;
 }
  
 .tdTable_minor{
    border-top-style:solid;
    border-top-width:1pt;
    border-left-style:solid;
    border-left-width:1pt;
    border-bottom-style:solid;
    border-bottom-width:1pt;
    border-right-style:solid;
    border-right-width:1pt;
    text-align: left;
 }
 
 .tableHeaderData_minor {
    color: black;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight:bold;
    text-decoration: none;
    font-size: 10pt;
    width:100%;
    text-align: center;
    padding-left: .5rem;
 }
 
 .table_data {
    color: black;
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-weight:normal;
    text-decoration: none;
    font-size: 10pt;
    width:100%;
    text-align: left;
    padding-left: .5rem;
 }