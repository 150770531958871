.navbar {
  /* flex-shrink: 0;
  top: 50px;
  left: 0px; */
  font-size: 11pt;
  zoom: 0.9;
  zoom: 90%;
}

.lightText {
  color: #eef8ff;
  text-align: center !important;
  text-decoration: none;
}

.MuiDrawer-paperAnchorLeft {
  background-color: #021733 !important;
  width: 110px;
  border-right: 0px !important;
}

.MuiListItem-root.Mui-selected {
  opacity: 0.9;
  background-color: rgb(255 255 255 / 17%) !important;
  border-right: 5px solid rgba(255, 255, 255, 0.85) !important;
}

.MuiListItem-button:hover {
  opacity: 1;
  background-color: rgb(255 255 255 / 17%) !important;
}

.MuiList-root {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.MuiToolbar-root {
  padding-bottom: 10px;
}
