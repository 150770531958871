.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  justify-content: center;
  gap: 20px;
}

p {
  font-size: 20px;
}