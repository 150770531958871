* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: EMPrint;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  zoom: 0.9; /* Other non-webkit browsers */
  zoom: 90%; /* Webkit browsers */

  /* transform: scale(1);
  transform-origin: 0 0; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

 .submissionFormDatePicker .MuiFormControl-root{width:41.5%}

 .managementSearch .ForwardRef\(TextField\)-root-2 {width:"15rem"}
