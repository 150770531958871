#blend-form-print {
   visibility: hidden;
   position: absolute;
   left: 0;
   top: 0;
}

@media print {
   body * {
      visibility: hidden;
   }
   #blend-form-print,
   #blend-form-print * {
      visibility: visible;
   }
   #blend-form-print {
      position: absolute;
      left: 0;
      top: 0;
   }

   html, body {
      height:100vh; 
      margin: 0 !important; 
      padding: 0 !important;
      overflow: hidden;
    }

   @page {size: landscape}
}

table {
   page-break-inside: avoid;
 }
 
/* .proprietaryHeader {
   color: black;
   font-family: Verdana, sans-serif;
   font-style: normal;
   font-weight:bold;
   text-decoration: none;
   font-size: 24pt;
   text-align: center;
   width: 100%;
} */

.minorHeader {
   color: black;
   font-family: Verdana, sans-serif;
   font-style: normal;
   font-weight:normal;
   text-decoration: none;
   font-size: 18pt;
   width: 100%
}


.tableTop {
   width: 100%;
   text-align: left;
}

.tableTopColHeaderRec {
   flex-direction: column;
   align-items: left;
   justify-content: left;
   height: 35;
   padding: 2px;
   width: 20%;
}

.topMenuItemHead {
   color: black;
   font-family: Arial, sans-serif;
   font-style: normal;
   font-weight: bold;
   text-decoration: none;
   font-size: 12pt;
   text-align: start;
}

.topMenuItemData {
   color: black;
   font-family: Arial, sans-serif;
   font-style: normal;
   font-weight: normal;
   text-decoration: none;
   font-size: 12pt;
   padding-left: 1rem;
}

.topMenuTableRow {
   padding-top: .5rem;
}

.tableMethods {
   margin-top: 2rem;
   border-collapse: collapse;
   width:100%;
}

.tdTableMethods{
   width:100%;
   border-top-style:solid;
   border-top-width:1pt;
   border-left-style:solid;
   border-left-width:1pt;
   border-bottom-style:solid;
   border-bottom-width:1pt;
   border-right-style:solid;
   border-right-width:1pt;
   text-align: center;
}

.tdTableMethods_minor{
   border-top-style:solid;
   border-top-width:1pt;
   border-left-style:solid;
   border-left-width:1pt;
   border-bottom-style:solid;
   border-bottom-width:1pt;
   border-right-style:solid;
   border-right-width:1pt;
   text-align: left;
}


.tableMethodsHeaderData {
   color: black;
   font-family: Verdana, sans-serif;
   font-style: normal;
   font-weight:bold;
   text-decoration: none;
   font-size: 10pt;
   width:100%;
   text-align: center;
}

.tableMethodsHeaderData_minor {
   color: black;
   font-family: Verdana, sans-serif;
   font-style: normal;
   font-weight:bold;
   text-decoration: none;
   font-size: 10pt;
   width:100%;
   text-align: center;
   padding-left: .5rem;
}

.tableMethods_data {
   color: black;
   font-family: Verdana, sans-serif;
   font-style: normal;
   font-weight:normal;
   text-decoration: none;
   font-size: 10pt;
   width:100%;
   text-align: left;
   padding-left: .5rem;
}


* { margin: 0;
   padding: 0;
   text-indent: 0;
   }

 .container {
   display: flex;
   width: 100%;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
 }
